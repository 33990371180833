import { Data } from '../data';
import { Store } from '../store';
import { TransportDelivery } from './transportDelivery';

export class TransportDeliveries extends Data {
	objectName = 'TransportDeliveries';

	url = 'TransportDeliveries';
	publicUrl = 'TransportDeliveriesPublic';
	todoPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	cancelledPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	subscribedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	declinedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	asssignedPaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	donePaging = {
		currentPage: 1,
		totalItems: 0,
		pageSize: 20,
		count: 1,
		sortingFieldId:'',
		searchFieldId:null,
		searchText:''
	};
	createData(paging){
		var data = 
		{ 
			top: paging.pageSize,
			page: paging.currentPage
		}
		if (paging.sortingFieldId  !== null && paging.sortingFieldId > -1){
			data.sortingFieldId = paging.sortingFieldId;
		}
		if (paging.searchFieldId !== null && paging.searchFieldId > -1)
		{
			data.searchFieldId = paging.searchFieldId;
		}
		if (paging.searchText !== null && paging.searchText !== "")
		{
			data.searchText = paging.searchText;
		}
		return data;
	}
	getDownloadUrl(fileId, isPublic,accessCode){
		if (isPublic)
		{
			return  Data.baseUrl + this.publicUrl + "/Download?accessCode=" + accessCode + "&file=" + fileId;
		}
		return Data.baseUrl + this.url + "/Download?file=" + fileId;
	}
	getTransportDeliveryPricesToDo(reload, thenCallBack) {
		return super.list(this.createData(this.todoPaging), reload, 'todo', '/Todo', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesCancelled(reload, thenCallBack) {
		return super.list(this.createData(this.cancelledPaging), reload, 'cancelled', '/Cancelled', thenCallBack, thenCallBack);
	}

	
	getTransportDeliveryPricesSubscribed(reload, thenCallBack) {
		return super.list(this.createData(this.subscribedPaging), reload, 'subscribed', '/Subscribed', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDeclined(reload, thenCallBack) {
		return super.list(this.createData(this.declinedPaging), reload, 'declined', '/Declined', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesAssigned(reload, thenCallBack) {
		return super.list(this.createData(this.asssignedPaging), reload, 'asssigned', '/Assigned', thenCallBack, thenCallBack);
	}
	getTransportDeliveryPricesDone(reload, thenCallBack) {
		return super.list(this.createData(this.donePaging), reload, 'done', '/Done', thenCallBack, thenCallBack);
	}

	getDeliveryInformation(guid, force, thenCallback, catchCallBack) {
		return super.get(Data.baseUrl + this.url + '/deliveryInformation?accessCode=' + guid, force, (r) => thenCallback(this.fetchListItem(r)), catchCallBack, guid, false);
	}
	getDeliveryInformationPublic(guid, force, thenCallback, catchCallBack) {
		return super.get(this.fullPublicUrl + '/deliveryInformation?accessCode=' + guid, force, (r) => thenCallback(this.fetchListItem(r)), catchCallBack, guid, false);
	}
	get fullPublicUrl() {
		return Data.baseUrl + this.publicUrl;
	}
	completeDelivery(guid, thenCallBack, catchCallBack) {
		return this.store.dataAccess
			.post(this.fullPublicUrl + '/completeDelivery?accessCode=' + guid, false, false, null, null, false)
			.then((r) => thenCallBack(this.fetchListItem(r)))
			.catch(catchCallBack);
	}
	fetchListItem(item) {
		// standaard teruggeven. In andere objecten kan hier een subobject gemaakt worden
		let returnItem = new TransportDelivery();
		returnItem.setStore(this.store);
		returnItem.fetch(item);

		return returnItem;
	}
	setPagingItems(result, cacheId) {
		switch (cacheId) {
			case 'todo':
				this.todoPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
				break;
			case 'cancelled':
				this.cancelledPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
			case 'subscribed':
				this.subscribedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
			case 'declined':
				this.declinedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
			case 'asssigned':
				this.asssignedPaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
			case 'done':
				this.donePaging = {
					currentPage: result.page,
					totalItems: result.count,
					pageSize: result.top,
					listRequest: null,
					sortingFieldId:result.sortingFieldId,
					searchFieldId:result.searchFieldId,
					searchText:result.searchText
				};
		}
	}
}
