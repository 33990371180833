import { Data } from '../data';
import { Store } from '../store';
import { TransportSortingField } from './transportSortingField.js';

export class TransportSorting extends Data {
	objectName = 'TransportSorting';
	sortingFields = {};
	url = 'TransportSorting';
   
    getSortingList(pageName){
		if (typeof this.sortingFields[pageName] === 'undefined') {
            this.sortingFields[pageName] = null;
        }
        if (this.sortingFields[pageName] !== null){
            return this.sortingFields[pageName];
        }
		this.list({pageName:pageName},null,null,null, (result)=>{this.sortingFields[pageName] = result})
		return [];
    }
  
	
	getById(id, pageName){
		if (typeof this.sortingFields[pageName] === 'undefined') {
            this.sortingFields[pageName] = null;
        }
        if (this.sortingFields[pageName] !== null){
			return this.sortingFields[pageName].find(item=>item.Oid===id);
        }
		this.list({pageName:pageName},null,null,null, (result)=>{this.sortingFields[pageName] = result})
		return [];
	}

    fetchListItem(item) {
		var field = new TransportSortingField();
		field.fetch(item);
		return field;
	}
}