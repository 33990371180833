
import { Data } from '../data';

export class TransportSortingField extends Data {
	objectName = 'TransportDelivery';
	url = 'TransportDeliveries';
    Oid = '';
    Description = '';


}