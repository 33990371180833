import { Data } from '../data';
import { Store } from '../store';


export class TransportUserPreferences extends Data {
	objectName = 'TransportUserPreferences';

	url = 'TransportUserPreferences';
    userPreferences = {};
    save(data, thenCallBack, catchCallBack) {
        super.save(
		
			data,
			'',
			(result) => {

                this.userPreferences[data.pageName] = result
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}
   
    getUserPreferences(page, thenCallBack){
        if (typeof this.userPreferences[page] === 'undefined' || this.userPreferences[page]  === null ) {
            return this.loadCurrentUserPreferences(page,  thenCallBack);
        }
		if (typeof thenCallBack === 'function')
		{
			thenCallBack(this.userPreferences[page]);
		}
        return this.userPreferences[page];
    }
    loadCurrentUserPreferences(page, thenCallBack, catchCallBack) {
        if (typeof this.userPreferences[page] === 'undefined') {
            this.userPreferences[page] = null;
        }
        if (this.userPreferences[page] !== null){
            return this.userPreferences[page];
        }


        let url = Data.baseUrl + this.url + '?pageName=' + page;
		this.get(
			url,
			false,
			(result) => {
                this.userPreferences[page] = result
				if (thenCallBack != null) {
					thenCallBack(this.userPreferences[page]);
				}
               
			},
			(exception) => {
				console.log(exception)
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
               
			}
		);

	}


}