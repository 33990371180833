
import { Data } from '../data';

export class TransportSearchField extends Data {
	objectName = 'TransportSeachFields';
	url = 'TransportSeachFields';
    Oid = '';
    Name = '';
    DefaultSearchField = false;
    


}