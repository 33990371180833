import { Data } from '../data';
import { Store } from '../store';
import { TransportSearchField } from './transportSearchField.js';

export class TransportSearchFields extends Data {
	objectName = 'TransportSearchFields';
	searchFields = {};
	url = 'TransportSearchFields';
   
    getSearchFieldsList(pageName, thenCallBack){
		if (typeof this.searchFields[pageName] === 'undefined') {
            this.searchFields[pageName] = null;
        }
        if (this.searchFields[pageName] !== null){
			if (typeof thenCallBack === 'function'){
				thenCallBack(null, this.searchFields[pageName] );
			}
            return this.searchFields[pageName];
        }
		this.list(
			{pageName:pageName},
			null,
			null,
			null, 
			(result, list) =>
				{
					this.searchFields[pageName] = result
					if (typeof thenCallBack === 'function'){
						thenCallBack(result, list);
					}
				})
		return [];
    }
  
	
	getById(id, pageName){
		if (typeof this.searchFields[pageName] === 'undefined') {
            this.searchFields[pageName] = null;
        }
        if (this.searchFields[pageName] !== null){
			return this.searchFields[pageName].find(item=>item.Oid===id);
        }
		this.list({pageName:pageName},null,null,null, (result)=>{this.searchFields[pageName] = result})
		return [];
	}

    fetchListItem(item) {
		var field = new TransportSearchField();
		field.fetch(item);
		return field;
	}
}