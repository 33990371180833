import { Data } from '../data';

export class TransportDeliveryMessage extends Data {
	constructor(store, message, transportDelivery) {
		super();
		this.store = store;
		if (typeof message !== 'undefined' && message != null) {
			this.id = message.id;
			this.author = message.author;
			this.dateModified = message.dateModified;
			this.parentMessageId = message.parentMessageId;
			this.editable = message.editable;
			this.message = message.message;
			this.messages = message.messages;
			if (typeof transportDelivery !== 'undefined' && transportDelivery != null) {
				this.transportDelivery = transportDelivery;
			}
		}
	}

	objectName = 'TransportDeliveryMessage';
	url = 'TransportDeliveries';

	id = -1;
	message = null;
	author = '';
	dateModified = '';
	parentMessage = null;
	editable = false;
	transportDelivery = null;
	messages = [];
	convertMessages() {
		// console.log(this.messages, this.messages.length);
		if (typeof this.messages !== 'undefined' && this.messages != null) {
			for (let i = 0; i < this.messages.length; i++) {
				this.messages[i] = new TransportDeliveryMessage(this.store, this.messages[i], this.transportDelivery);
				this.messages[i].convertMessages();
				this.messages[i].parentMessage = this;
			}
		}
	}

	send(transportDelivery, parentMessage, thenCallBack, catchCallBack) {
		if (this.transportDelivery === null) {
			this.transportDelivery = transportDelivery;
		}
		if (this.parentMessage === null) {
			this.parentMessage = parentMessage;
		}
		var data = {
			id: this.id,
			message: this.message,
			transportDeliveryOid: this.transportDelivery?.Id,
			parentMessageId: this.parentMessage?.id,
		};

		this.save(
			data,
			'/SaveMessage',
			() => {
				if (typeof this.transportDelivery !== 'undefined' && this.transportDelivery != null) {
					this.transportDelivery.getMessages();
					
				}
				if (thenCallBack != null) {
					thenCallBack();
				}
			},
			(exception) => {
				console.log(exception);
				if (catchCallBack != null) {
					catchCallBack(exception);
				}
			},
		);
	}
}
